import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as algoliaHelpers from 'shared/algoliaHelpers'
import _ from 'lodash'

const TAG_KEYS = {
    p1: 'p1',
    p2: 'p2',
    p3: 'p3'
}

const SECTIONS = {
    TAGS: 'tags',
    SEARCH: 'search'
}

const currentSection = window.location.href.split('/')[3]

const isLocal = window.location.href.indexOf('localhost') > 0
const urlPrefix = isLocal ? 'http://localhost:3001' : ''

export const getAccessToken = () => {
    let oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'))
    if (!oktaStorage || !oktaStorage.accessToken) {
        return
    }
    return oktaStorage.accessToken.value || oktaStorage.accessToken.accessToken
}

export const fetchUserRole = createAsyncThunk('app/setUserRole', async (email) => {
    try {
        let accessToken = getAccessToken()
        const response = await fetch(
            `${urlPrefix}/api/v1/users/${email ? email : 'juancarlos.cancela@gonimbly.com'}/role`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${accessToken}`
                }
            }
        )

        const userRole = await response.json()

        return userRole && userRole.role ? userRole.role : 'OPERATIONS'
    } catch (e) {
        console.log(e)
    }
})

export const fetchTagsByUserTag = createAsyncThunk('app/setTagsByUserTag', async (userTag) => {
    try {
        let accessToken = getAccessToken()
        const response = await fetch(`${urlPrefix}/api/v1/tag`, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`
            }
        })
        const tags = await response.json()
        const userTagsP1 = tags.filter(
            (tagItem) =>
                tagItem.tags.map((t) => t.toLowerCase()).filter((t) => t === `${userTag}-p1`)
                    .length > 0
        )
        const userTagsP2 = tags.filter(
            (tagItem) =>
                tagItem.tags.map((t) => t.toLowerCase()).filter((t) => t === `${userTag}-p2`)
                    .length > 0
        )
        const userTagsP3 = tags.filter(
            (tagItem) =>
                tagItem.tags.map((t) => t.toLowerCase()).filter((t) => t === `${userTag}-p3`)
                    .length > 0
        )
        const userTagsWithDetails = {
            p1: [],
            p2: [],
            p3: []
        }
        for (const tag of userTagsP1) {
            const tagItemDetails = await algoliaHelpers.getByObjectId(tag.id)
            const details = _.get(tagItemDetails, 'results[0]', {})
            userTagsWithDetails.p1.push({
                tag,
                details
            })
        }
        for (const tag of userTagsP2) {
            const tagItemDetails = await algoliaHelpers.getByObjectId(tag.id)
            const details = _.get(tagItemDetails, 'results[0]', {})
            userTagsWithDetails.p2.push({
                tag,
                details
            })
        }
        for (const tag of userTagsP3) {
            const tagItemDetails = await algoliaHelpers.getByObjectId(tag.id)
            const details = _.get(tagItemDetails, 'results[0]', {})
            userTagsWithDetails.p3.push({
                tag,
                details
            })
        }
        return userTagsWithDetails
    } catch (e) {
        console.log(e)
    }
})

export const fetchTagResults = createAsyncThunk(
    'app/setTagResults',
    async ({ userTag, page, p, tagResults }) => {
        let resp
        if (p) {
            resp = await algoliaHelpers.queryUserTags(userTag, page, p)
        } else {
            resp = await algoliaHelpers.queryUserTags(userTag)
        }
        algoliaHelpers.clearCache()
        const { p1, p2, p3 } = TAG_KEYS
        let hits, pages
        let updatedTagResults = {
            [p1]: {},
            [p2]: {},
            [p3]: {}
        }

        if (p) {
            hits = resp?.hits
            pages = resp.nbPages
            page = resp.page
            updatedTagResults = {
                ...tagResults,
                [p]: { hits, pages, page: resp.page }
            }
        } else {
            Object.keys(TAG_KEYS).forEach((key) => {
                let tags = resp[key]
                hits = tags.hits
                pages = tags.nbPages
                updatedTagResults = {
                    ...updatedTagResults,
                    [key]: { hits, pages, page: tags.page }
                }
            })
        }

        return updatedTagResults
    }
)

export const removeTag = createAsyncThunk('app/removeTag', async ({ id, removedTag, userTag }) => {
    try {
        let accessToken = getAccessToken()
        const tagsMap = {}
        const response = await fetch(`${urlPrefix}/api/v1/tag`, {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`
            }
        })
        const tagsByUserTag = await response.json()
        tagsByUserTag.forEach((tag) => {
            tagsMap[tag.id] = tag
        })
        const tags = tagsMap[id].tags.filter(
            (tag) => !tag.toLowerCase().includes(userTag.toLowerCase())
        )
        await fetch(`${urlPrefix}/api/v1/tag`, {
            method: 'POST',
            cache: 'no-cache',
            body: JSON.stringify({
                id,
                tags,
                type: tagsMap[id].type
            }),
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`
            }
        })
        await fetch(`${urlPrefix}/api/v1/tag/history`, {
            method: 'POST',
            cache: 'no-cache',
            body: JSON.stringify({
                id,
                userId: userTag,
                value: userTag,
                operationType: 'remove',
                creationDate: new Date().toISOString()
            }),
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`
            }
        })
        window.location = `${window.origin}/tags`
    } catch (e) {
        console.log(e)
    }
})

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        value: 2323,
        customHits: [],
        tagResults: null,
        tagResultsStatus: 'idle',
        tagsByUserTag: { p1: [], p2: [], p3: [] },
        tagsByUserTagStatus: 'idle',
        userTag: null,
        hasInput: false,
        tagsByEmail: {},
        isTagsView: currentSection.includes(SECTIONS.tags),
        shouldRenderSearchResults: false,
        hitLoadingItems: {},
        userRole: 'OPERATIONS'
    },
    reducers: {
        increment: (state) => {
            state.value += 1
        },
        decrement: (state) => {
            state.value -= 1
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload
        },
        updateCustomHits: (state, action) => {
            state.customHits = action.payload
        },
        removeTagResultItemByObjectID: (state, action) => {
            state.customHits = state.customHits.filter((h) => h.objectID !== action.payload)
        },
        setUserTag: (state, action) => {
            state.userTag = action.payload
        },
        setHasInput: (state, action) => {
            state.hasInput = action.payload
        },
        setTagsByEmail: (state, action) => {
            state.tagsByEmail = action.payload
        },
        setIsTagsView: (state, action) => {
            state.isTagsView = action.payload
        },
        setShouldRenderSearchResults: (state, action) => {
            state.shouldRenderSearchResults = action.payload
        },
        setHitLoadingItem: (state, action) => {
            state.hitLoadingItems[action.payload.id] = action.payload.value
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchTagResults.pending, (state, action) => {
                state.tagResultsStatus = 'loading'
            })
            .addCase(fetchTagResults.fulfilled, (state, action) => {
                state.tagResultsStatus = 'succeeded'
                state.tagResults = action.payload
            })
            .addCase(fetchTagResults.rejected, (state, action) => {
                state.tagResultsStatus = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchTagsByUserTag.pending, (state, action) => {
                state.tagsByUserTagStatus = 'loading'
            })
            .addCase(fetchTagsByUserTag.fulfilled, (state, action) => {
                state.tagsByUserTagStatus = 'succeeded'
                state.tagsByUserTag = action.payload ? action.payload : { tags: [] }
            })
            .addCase(fetchTagsByUserTag.rejected, (state, action) => {
                state.tagsByUserTagStatus = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchUserRole.pending, (state, action) => {
                state.fetchUserRoleStatus = 'loading'
            })
            .addCase(fetchUserRole.fulfilled, (state, action) => {
                state.fetchUserRoleStatus = 'succeeded'
                state.userRole = action.payload ? action.payload : 'OPERATIONS'
            })
            .addCase(fetchUserRole.rejected, (state, action) => {
                state.fetchUserRoleStatus = 'failed'
                state.error = action.error.message
            })
            .addCase(removeTag.pending, (state, action) => {
                state.tagsByUserTagStatus = 'loading'
            })
            .addCase(removeTag.fulfilled, (state, action) => {
                state.tagsByUserTagStatus = 'succeeded'
                state.tagsByUserTag = action.payload ? action.payload : { tags: [] }
            })
            .addCase(removeTag.rejected, (state, action) => {
                state.tagsByUserTagStatus = 'failed'
                state.error = action.error.message
            })
    }
})

export const {
    increment,
    decrement,
    incrementByAmount,
    updateCustomHits,
    removeTagResultItemByObjectID,
    setTagResults,
    setUserTag,
    setHasInput,
    setIsTagsView,
    setShouldRenderSearchResults,
    setHitLoadingItem
} = appSlice.actions

export default appSlice.reducer
