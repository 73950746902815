import * as React from 'react'
import moment from 'moment'
import { DataGrid } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import CryptoJS from 'crypto-js'

export function DataGridTable({ data, props = {}, sortable = 'yes' }) {
    if (!data || data.length === 0) return

    const handleCellClick = (params) => {}

    const columns = Object.keys(data[0]).map((col) => {
        let column = {
            field: col,
            headerName: col,
            flex: Object.keys(data[0]).length + 1,
            headerClassName: 'super-app-theme--header',
            cellClassName: 'super-app-theme--cell',
            editable: false,
            hide: col === 'id' ? true : false,
            sortable: sortable === 'yes' ? true : false
        }
        if (col === 'Last Interaction' || col === 'Date') {
            column = {
                ...column,
                valueFormatter: (params) => {
                    const value = moment(params?.value).format('MM/DD/YYYY')
                    return value
                }
            }
        }
        if (
            col === 'Closest Contact' ||
            col === 'First Contact' ||
            col === 'Latest Contact' ||
            col === 'Email' ||
            col === 'From' ||
            col === 'To' ||
            col === 'Organizer'
        ) {
            column = {
                ...column,
                renderCell: (params) => (
                    <a
                        rel="noreferrer"
                        target="_blank"
                        style={{ color: 'black', textDecoration: 'none', cursor: 'pointer' }}
                        href={`mailto:${params.value}`}>
                        {params && params.value ? params.value.toString() : ''}
                    </a>
                )
            }
        }

        if (col === 'Name') {
            column = {
                ...column,
                renderCell: (params) => (
                    <a
                        rel="noreferrer"
                        target="_blank"
                        style={{ color: 'black', textDecoration: 'none' }}
                        href={`/details/${CryptoJS.HmacSHA256(params.row.Email, ':)').toString()}`}>
                        {params.value}
                    </a>
                )
            }
        }
        return column
    })
    const d = {
        columns,
        rows: data,
        ...props
    }
    return (
        <Box
            sx={{
                width: 'fit-content(4000px)',
                '& .super-app-theme--header': {
                    backgroundColor: '#212B36',
                    color: 'white',
                    fontWeight: 'bolder',
                    fontSize: '16px'
                },
                '& .super-app-theme--cell': {
                    backgroundColor: '#D9D9D9',
                    color: 'black',
                    borderRight: '1px solid black',
                    fontSize: '16px',
                    borderBottom: '1px solid black !important'
                },
                background: '#D9D9D9',
                border: '1px solid black',
                borderRadius: 1,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
            }}>
            <DataGrid
                onCellClick={handleCellClick}
                autoHeight={true}
                pageSize={10}
                pagination
                rowsPerPageOptions={[10]}
                disableSelectionOnClick={true}
                sx={{
                    boxShadow: 2,
                    borderRadius: 5,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    color: 'black !important',
                    '& .MuiDataGrid-cell:hover': {
                        color: 'primary.main'
                    },

                    '& .MuiTablePagination-toolbar': {
                        color: 'black',
                        pt: 2
                    }
                }}
                {...d}
            />
        </Box>
    )
}
