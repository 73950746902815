import React, { useEffect, useMemo } from 'react'
import { Route, useHistory, Switch } from 'react-router-dom'
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import UnauthenticatedLandingPage from '../../pages/Login'
import Login from './Login'
import HomePage from '../../pages/Home'
import TagsPage from '../../pages/Tags'
import AdminPage from '../../pages/Admin'
import ItemDetailsPage from '../../pages/ItemDetails'
import { oktaAuthConfig, oktaSignInConfig } from './config'
import { useSelector } from 'react-redux'

const AppWithRouterAccess = () => {
    const history = useHistory()
    const userRole = useSelector((state) => state.app.userRole)
    const oktaAuth = useMemo(() => new OktaAuth(oktaAuthConfig), [])

    const customAuthHandler = () => {
        history.push('/login')
    }

    const restoreOriginalUri = async (_oktaAuth) => {
        history.replace(toRelativeUrl('/search', window.location.origin))
    }

    useEffect(() => {
        const start = async () => await oktaAuth.start()
        start()
    }, [oktaAuth])

    useEffect(() => {
        //Force http redirection
        if (
            window.location.protocol === 'http:' &&
            window.location.href.indexOf('localhost') === -1
        ) {
            window.location.href = window.location.href.replace('http:', 'https:')
        }

        //Force redirection from Heroku to Greybase URLs
        if (window.location.href.indexOf('sleepy') !== -1) {
            window.location.href = 'https://greybase-dev.greylock.com'
        }

        if (window.location.href.indexOf('next-greybase.herokuapp') !== -1) {
            return
        }

        if (window.location.href.indexOf('greybase.herokuapp') !== -1) {
            window.location.href = 'https://greybase.greylock.com'
        }
    }, [])

    const oktaLoginPage = () => <Login config={oktaSignInConfig} />

    return (
        <Security
            oktaAuth={oktaAuth}
            onAuthRequired={customAuthHandler}
            restoreOriginalUri={restoreOriginalUri}>
            <Switch>
                <SecureRoute path="/details/:id" component={ItemDetailsPage} />
                {userRole !== 'OPERATIONS' && <SecureRoute path="/tags" component={TagsPage} />}
                {userRole === 'ADMIN' && <SecureRoute path="/admin" component={AdminPage} />}
                <Route path="/" exact component={UnauthenticatedLandingPage} />
                <Route path="/login/callback" component={LoginCallback} />
                <Route path="/login" render={oktaLoginPage} />
                <SecureRoute path="/next" component={HomePage} />
                <SecureRoute path="/" component={HomePage} />
                <SecureRoute path="/search" component={HomePage} />
                {/* <SecureRoute path="/" component={AuthenticatedRoot} /> */}
                {/* <SecureRoute path="/search" component={AuthenticatedRoot} /> */}
                {/* <SecureRoute path="/:id" exact component={FullPageRecord} /> */}
            </Switch>
        </Security>
    )
}
export default AppWithRouterAccess
