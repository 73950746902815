module.exports = {
    'jerry@greylock.com': {
        name: 'Jerry Chen',
        userTag: 'jc',
        avatarSrc: 'https://cdn-icons-png.flaticon.com/512/892/892781.png?w=360'
    },
    'hollyrose@greylock.com': {
        name: 'HollyRose Faith',
        userTag: 'hrf',
        avatarSrc: 'https://cdn-icons-png.flaticon.com/512/892/892781.png?w=360'
    },
    'mduboe@greylock.com': {
        name: 'Mike Duboe',
        userTag: 'md',
        avatarSrc: 'https://cdn-icons-png.flaticon.com/512/892/892781.png?w=360'
    },
    'kbuckley@greylock.com': {
        name: 'Kerry Buckley',
        userTag: 'kb',
        avatarSrc: 'https://ca.slack-edge.com/T024F5B8H-U1NSA70N7-60505b1d65be-72'
    },
    'mbernal@greylock.com': {
        name: 'Manuel Bernal',
        userTag: 'mb',
        avatarSrc: 'https://ca.slack-edge.com/T024F5B8H-U1KDET7MW-9a2140c15413-72'
    },
    'dthacker@greylock.com': {
        name: 'David Thacker',
        userTag: 'dt',
        avatarSrc: 'https://cdn-icons-png.flaticon.com/512/892/892781.png?w=360'
    },
    'criley@greylock.com': {
        name: 'Corinne Riley',
        userTag: 'cr',
        avatarSrc: 'https://cdn-icons-png.flaticon.com/512/892/892781.png?w=360'
    },
    'jrisch@greylock.com': {
        name: 'Jason Risch',
        userTag: 'jr',
        avatarSrc: 'https://cdn-icons-png.flaticon.com/512/892/892781.png?w=360'
    },
    'smotamedi@greylock.com': {
        name: 'Saam Motamedi',
        userTag: 'sm',
        avatarSrc: 'https://cdn-icons-png.flaticon.com/512/892/892781.png?w=360'
    },
    'juancarlos.cancela@gonimbly.com': {
        name: 'Juan Carlos Cancela',
        userTag: 'ts',
        avatarSrc: 'https://ca.slack-edge.com/T02508T49-U02HG6CBJQL-f781977df923-512'
    },
    'jcancela@greylock.com': {
        name: 'Juan Carlos Cancela',
        userTag: 'xs',
        avatarSrc: 'https://ca.slack-edge.com/T02508T49-U02HG6CBJQL-f781977df923-512'
    },
    'mfrai@greylock.com': {
        name: 'Maya Frai',
        userTag: 'mf',
        avatarSrc: 'https://cdn-icons-png.flaticon.com/512/892/892781.png?w=360'
    },
    "courtney.milligan@twostoryrobot.com": {
        name: 'Courtney Milligan',
        userTag: 'cm',
        avatarSrc: 'https://images-codaio.imgix.net/https%3A%2F%2Flh3.googleusercontent.com%2Fa%2FAGNmyxaSeQwCz5PWxuQbOCuxaP0XGhxlKuqJQXBtWZPR%3Ds96-c?ixlib=js-3.8.0&auto=format%2Ccompress&fit=crop&w=360&h=360&crop=faces&s=d51c397c075b0bc74711f31cb3d4c193'
    }
}
