import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import store from './redux/store'
import { Provider } from 'react-redux'
import AppWithRouterAccess from './components/authentication/AppWithRouterAccess'
import ThemeProvider from './theme'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

// scroll bar
import 'simplebar/src/simplebar.css'

// pattern.css
import 'pattern.css/dist/pattern.css'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'

// contexts
import { SettingsProvider } from './contexts/SettingsContext'
import * as serviceWorker from './serviceWorker'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
    <SettingsProvider>
        <ThemeProvider>
            <Provider store={store}>
                <Router>
                    <AppWithRouterAccess />
                </Router>
            </Provider>
        </ThemeProvider>
    </SettingsProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers:
serviceWorker.unregister({})
