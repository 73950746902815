import algoliasearch from 'algoliasearch'
import moment from 'moment'
;(async function () {
    const isLocalhost = () => {
        return (
            window &&
            window.location &&
            window.location.host &&
            window.location.host.includes('localhost')
        )
    }

    let diff = 'not-defined'
    const renewalDateIso = localStorage.getItem('algolia-renewal-date')
    const algoliaApiKey = localStorage.getItem('algolia-api-key')

    if (algoliaApiKey) {
        if (renewalDateIso) {
            const now = moment()
            diff = now.diff(moment(renewalDateIso), 'day')
        }
        if (diff !== 'not-defined' && diff < 15) {
            return
        }
    }

    const oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'))
    if (!oktaStorage || !oktaStorage.accessToken) {
        return
    }
    const token = oktaStorage.accessToken.accessToken
    let response
    try {
        response = await fetch(`${isLocalhost() ? 'http://localhost:3001' : ''}/securekey`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    } catch (e) {
        console.log(e)
    }
    if (!response) return
    const jsonResponse = await response.json()
    localStorage.setItem('algolia-api-key', jsonResponse.key)
    localStorage.setItem('algolia-renewal-date', moment().toISOString())
    setTimeout(() => window.location.reload(), 1500)
})()

//console.log('process.env.REACT_APP_APP_KEY => ', process.env.REACT_APP_APP_KEY)
//console.log('algolia-api-key => ', localStorage.getItem('algolia-api-key'))
export const searchClient = algoliasearch(
    process.env.REACT_APP_APP_KEY,
    localStorage.getItem('algolia-api-key'),
    {
        advancedSyntax: true
    }
)

/**
 * Isomorphic function that returns the corresponding
 * index name.
 * If its local or development, it returns development
 * If its prod or server side (sync), production.
 *
 * @returns String the name of the corresponding index name
 */
export const getIndexName = () => 'greybase_next_index'

const algoliaIndex = searchClient.initIndex(getIndexName())

export const updateRecords = async (records) => {
    try {
        if (!records || !records.length) return []
        await algoliaIndex
            .partialUpdateObjects(records, {
                createIfNotExists: true
            })
            .wait()
            .then((response) => {
                console.log(response)
            })
    } catch (error) {
        console.log('Error updating to Algolia DB.')
        console.log({ error })
        console.log({ stackTrace: error.transporterStackTrace })
        console.log({ Response: error.transporterStackTrace[0].response })
    }
}

export const requestRecordsByObjectID = async (objectIDs) => {
    try {
        const queryObjects = objectIDs.map((objectID) => {
            return { indexName: getIndexName(), objectID }
        })

        const res = await searchClient.multipleGetObjects(queryObjects)
        const { results } = await res

        return results
    } catch (error) {
        console.log({
            message: 'Error when requesting multiple objects',
            error
        })
    }
}

export const getByObjectId = async (objectId) => {
    const result = await algoliaIndex.getObjects([objectId])
    return result
}

export const requestTeamMembersLookUp = async () => {
    try {
        const lookUpId = 'teamMembersLookup'
        const result = await algoliaIndex.getObject(lookUpId)
        return result
    } catch (error) {
        console.log({
            message: 'Error when requesting teamMembersLookup',
            error
        })
    }
}

export const queryUserTags = async (userTagRoot = 'jc', page = 0, p = null) => {
    let config = {
        restrictSearchableAttributes: ['fileId', 'companyRoot'],
        optionalFilters: ['type:person', 'type:conversation', 'type:company'],
        highlightPostTag: '</ais-highlight-0000000000>',
        highlightPreTag: '<ais-highlight-0000000000>',
        clickAnalytics: false,
        analytics: false,
        hitsPerPage: 1000
    }
    if (page > 0) {
        config = { ...config, page }
    }
    try {
        if (p) {
            return await algoliaIndex.search(`${userTagRoot}-${p}`, {
                ...config
            })
        }
        //let tagsResponse = await fetch('http://localhost:3001/api/v1/tag')
        let oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'))
        if (!oktaStorage || !oktaStorage.accessToken) {
            return
        }
        let accessToken = oktaStorage.accessToken.accessToken
        let tagsResponse = await fetch('http://localhost:3001/api/v1/tag', {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`
            }
        })
        tagsResponse = await tagsResponse.json()

        let p1Ids = []
        let p2Ids = []
        let p3Ids = []

        tagsResponse.forEach((tagItem) => {
            const foundP1 = tagItem.tags.find(
                (element) => element.toLowerCase() === `${userTagRoot}-p1`
            )
            const foundP2 = tagItem.tags.find(
                (element) => element.toLowerCase() === `${userTagRoot}-p2`
            )
            const foundP3 = tagItem.tags.find(
                (element) => element.toLowerCase() === `${userTagRoot}-p3`
            )
            if (foundP1) p1Ids.push(tagItem.id)
            if (foundP2) p2Ids.push(tagItem.id)
            if (foundP3) p3Ids.push(tagItem.id)
        })

        const _getHits = async (ids) => {
            let res = {
                hits: [],
                pages: 1,
                page: 0
            }
            for (let id of ids) {
                let itemResult = await algoliaIndex.search(id, {
                    ...config
                })
                itemResult.hits = itemResult.hits.filter(
                    (hit) => hit.companyRoot === id || hit.fileId === id
                )
                itemResult.hits.forEach((hit) => {
                    hit.id = id
                })
                if (!res.query) {
                    res = itemResult
                } else {
                    res.hits = res.hits.concat(itemResult.hits)
                }
            }
            return res
        }

        const p1 = await _getHits(p1Ids)
        const p2 = await _getHits(p2Ids)
        const p3 = await _getHits(p3Ids)

        return { p1, p2, p3 }
    } catch (error) {
        console.log({ message: 'Error querying user tags', error })
        console.log({ error })
    }
}

export const clearCache = async () => {
    searchClient.clearCache()
}

export const getEngagementConfig = async () => {
    /*
    USE CASE: Clasify Person Relationship Status
  */
    let configId = 'engagementConfig'
    try {
        let engagementConfig = await algoliaIndex.getObject(configId)
        return engagementConfig
    } catch (error) {
        console.log('Error retrieving previous sync from Algolia DB.')
        console.log({ error })
    }
}
