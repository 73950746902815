import { useSelector } from 'react-redux'
import { RefinementList } from 'react-instantsearch-dom'

const REFINEMENT_ATTRIBUTES = ['person', 'company', 'conversation']
const OPERATIONS_REFINEMENT_ATTRIBUTES = ['person', 'company']

/**
 * @description Refinement Component used by Algolia's Instant Search.
 *              This component allows filtering and refinement of searchs
 *              by company, people and conversations
 *
 * @author Juan Carlos Cancela <juancarlos.cancela@gonimbly.com>
 */
const Refinements = () => {
    const userRole = useSelector((state) => state.app.userRole)

    const attributes =
        userRole === 'OPERATIONS' ? OPERATIONS_REFINEMENT_ATTRIBUTES : REFINEMENT_ATTRIBUTES

    return (
        <div
            style={{
                color: '#eceff1',
                visibility: 'visible',
                paddingTop: 10
            }}
        >
            <RefinementList
                attribute="type"
                defaultRefinement={attributes}
                transformItems={(items) => {
                    const NON_EMPTY_REFINEMENT_ATTRIBUTES = []
                    attributes.forEach((attribute) => {
                        const filteredList = items.filter((item) => item.label === attribute)
                        if (filteredList && filteredList.length > 0 && filteredList[0].count > 0) {
                            NON_EMPTY_REFINEMENT_ATTRIBUTES.push(attribute)
                        }
                    })

                    return NON_EMPTY_REFINEMENT_ATTRIBUTES.map((attribute) => {
                        const filteredList = items.filter((item) => item.label === attribute)
                        if (filteredList && filteredList.length > 0) {
                            return filteredList[0]
                        } else {
                            return []
                        }
                    })
                }}
            />
        </div>
    )
}

export default Refinements
